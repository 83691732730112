import React, { useCallback, useEffect, useMemo, useState } from "react";

import Head from "next/head";
import { useRouter } from "next/router";
import { toast } from "react-toastify";

import Button from "../components/bits/Button";
import LogoWithTitle from "../components/bits/LogoWithTitle";
import TextLink from "../components/bits/TextLink";
import Timer from "../components/bits/Timer";
import InputGroup from "../components/blocks/InputGroup";
import useServerAPI from "../customHooks/useServerAPI";
import { setAuthToken, setCookieItem } from "../helpers/utils";
import Image from "next/image";
import { useAuthContext } from "../state/auth";
import { UPDATE_USER_ID } from "../state/auth/reducer";

const RESEND_TIMER = 60;
const MAX_LENGTH_OTP = 6;
const MAX_LENGTH_PASSOWRD = 25;
const MAX_LENGTH_PHONE = 10;

const LoginScreen = () => {
  const router = useRouter();
  const [, dispatch] = useAuthContext();

  // states
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [resendActive, setResendActive] = useState(false);
  const [inputFilled, setInputFilled] = useState(false);

  // API hooks
  const [sendOTPResponse, sendOTPIsLoading, sendOTP] = useServerAPI("sendOTP");
  const [verifyOTPResponse, verifyOTPIsLoading, verifyOTP] =
    useServerAPI("verifyOTP");
  const [
    loginWithPasswordResponse,
    loginWithPasswordIsLoading,
    loginWithPassword,
  ] = useServerAPI("loginWithPassword");

  const loginResponse = useMemo(
    () => loginWithPasswordResponse || verifyOTPResponse,
    [verifyOTPResponse, loginWithPasswordResponse]
  );
  const loginIsLoading = useMemo(
    () => verifyOTPIsLoading || loginWithPasswordIsLoading,
    [verifyOTPIsLoading, loginWithPasswordIsLoading]
  );

  // Functions
  const onSendOtp = (event) => {
    event.preventDefault();
    sendOTP(mobileNo);
    setResendActive(false);
  };

  const onVerifyOtp = useCallback(
    (event) => {
      if (event) event.preventDefault();
      if (password) {
        loginWithPassword({ password, phone: mobileNo.toString() });
      } else verifyOTP({ auth_code: otp, phone: mobileNo });
    },
    [otp, mobileNo, verifyOTP, loginWithPassword, password]
  );

  // Lifecycle methods
  useEffect(() => {
    if (sendOTPResponse?.status === "OTP Sent") {
      setOtpSent(true);
      toast.success("OTP Sent");
    } else if (sendOTPResponse?.error?.data?.status) {
      setErrorMessage(sendOTPResponse.error?.data?.status);
      toast.error(sendOTPResponse.error?.data?.status);
    } else if (sendOTPResponse?.message) {
      setErrorMessage(sendOTPResponse.message);
      toast.error(sendOTPResponse.message);
    }
  }, [sendOTPResponse]);

  useEffect(() => {
    if (loginResponse?.token) {
      setAuthToken(loginResponse?.token);
      setCookieItem("userId", loginResponse.user.id);
      dispatch({ type: UPDATE_USER_ID, payload: loginResponse.user.id });
      setCookieItem("isFieldExecutive", loginResponse.is_field_executive);
      setCookieItem("passwordLoginEnabled", loginResponse.allow_password_login);
      if (loginResponse.is_asm) {
        setCookieItem("isASMUser", true);
        router.push(
          `/asm?isASMUser=true&userId=${loginResponse.user.id}&userToken=${loginResponse?.token}`,
          "/asm"
        );
      } else {
        router.push(
          `/?userId=${loginResponse.user.id}&userToken=${loginResponse?.token}`,
          "/"
        );
      }
    } else if (loginResponse?.error?.data?.status) {
      setErrorMessage(loginResponse.error.data.status);
      toast.error(loginResponse.error?.data?.status);
    } else if (loginResponse?.message) {
      setErrorMessage(loginResponse.message);
      toast.error(loginResponse.message);
    }
  }, [router, loginResponse]);

  useEffect(() => {
    if (otp.length === MAX_LENGTH_OTP && !inputFilled) {
      setInputFilled(true);
      onVerifyOtp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilled, otp]);

  useEffect(() => {
    setInputFilled(false);
    setErrorMessage("");
  }, [otp, mobileNo, otpSent]);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <div className="flex flex-col justify-between w-full h-full p-4">
        <div>
          {otpSent && (
            <span className="cursor-pointer" onClick={() => setOtpSent(false)}>
              <Image
                src="/assets/icons/leftArrowBlack.svg"
                height={30}
                width={30}
                alt="leftArrowBlack"
              />
            </span>
          )}
          <LogoWithTitle className="flex flex-col items-center mt-20" />
        </div>
        {!otpSent ? (
          <form
            onSubmit={onSendOtp}
            className="flex flex-col justify-between h-2/3"
          >
            <div>
              <InputGroup
                label="Mobile Number"
                id="mobileNo"
                placeholder="Enter Your Mobile Number Here"
                type="number"
                maxLength={MAX_LENGTH_PHONE}
                value={mobileNo}
                handleChange={(event) => setMobileNo(event.target.value)}
              />
              <p className="text-sm text-[#bdbdbd]">
                We will send an OTP to this number which will be required for
                verification and login.
              </p>
              {errorMessage ? (
                <p className="mt-8 text-center text-red-600">{errorMessage}</p>
              ) : null}
            </div>
            <div className="text-center">
              <Button
                type="submit"
                disabled={
                  mobileNo.length !== MAX_LENGTH_PHONE || sendOTPIsLoading
                }
              >
                {sendOTPIsLoading ? "Loading..." : "Continue"}
              </Button>
              <p className="text-sm">By signing in, you agree to our</p>
              <span className="text-sm font-semibold text-primary">
                <a href="/terms" target="_blank">
                  Terms of Service &amp; Privacy Policy
                </a>
              </span>
            </div>
          </form>
        ) : (
          <form
            onSubmit={onVerifyOtp}
            className="flex flex-col justify-between h-2/3"
          >
            <div>
              <InputGroup
                label="OTP"
                id="otp"
                placeholder="XXXXXX"
                type="number"
                maxLength={MAX_LENGTH_OTP}
                value={otp}
                handleChange={(event) => setOTP(event.target.value)}
                autoFocus
              />
              <p className="text-sm text-[#bdbdbd] p-1">
                An OTP has been sent to
                <span
                  className="cursor-pointer text-[#666]"
                  onClick={() => setOtpSent(false)}
                >
                  {` +91 ${mobileNo}`}
                </span>
                . Kindly check your inbox and enter the OTP for verification and
                Login
              </p>
              {resendActive ? (
                <TextLink className="float-right" onClick={onSendOtp}>
                  Resend OTP
                </TextLink>
              ) : (
                <Timer
                  initialTime={RESEND_TIMER}
                  onComplete={() => setResendActive(true)}
                />
              )}
              {errorMessage ? (
                <p className="mt-8 text-center text-red-600">{errorMessage}</p>
              ) : null}
              {!!sendOTPResponse?.allow_password_login && (
                <>
                  <p className="mt-4 text-lg font-bold text-center">OR</p>
                  <InputGroup
                    label="Enter Password"
                    id="password"
                    placeholder="Enter Password Here"
                    type="password"
                    maxLength={MAX_LENGTH_PASSOWRD}
                    value={password}
                    handleChange={(event) => setPassword(event.target.value)}
                  />
                </>
              )}
            </div>
            <Button
              type="submit"
              disabled={
                (sendOTPResponse?.allow_password_login
                  ? password.length < 9
                  : otp.length !== MAX_LENGTH_OTP) || loginIsLoading
              }
            >
              {loginIsLoading
                ? "Loading..."
                : sendOTPResponse.allow_password_login
                ? "Continue"
                : "Verify OTP"}
            </Button>
          </form>
        )}
      </div>
    </>
  );
};

export const getServerSideProps = async ({ req, res }) => {
  res.setHeader(
    "Cache-Control",
    "no-cache, no-store, max-age=0, must-revalidate"
  );
  const { cookies } = req;
  if (cookies.userId && cookies.userToken) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};

export default LoginScreen;
