import React, { useEffect, useState } from "react";

const Timer = ({ initialTime, onComplete }) => {
  const [time, setTime] = useState(initialTime || 0);
  useEffect(() => {
    let timerID;
    if (time > 0) {
      timerID = setInterval(() => setTime((prevTime) => prevTime - 1), 1000);
    } else {
      onComplete();
    }

    return () => clearInterval(timerID);
  }, [onComplete, time]);

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60);
    let seconds = (millis % 60).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };
  return (
    <p className="float-right text-sm font-semibold text-gray-500">
      Resend OTP{" "}
      <span className="text-black">in {millisToMinutesAndSeconds(time)}</span>
    </p>
  );
};

export default Timer;
