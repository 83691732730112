import Image from "next/image";
import React from "react";

const LogoWithTitle = ({ className, hideTitle = false }) => {
  return (
    <div className={className}>
      {!hideTitle && <h1 className="text-lg font-semibold">Welcome to</h1>}
      <Image
        src="/assets/images/Vmotive.png"
        alt="logo"
        width={240}
        height={80}
      />
    </div>
  );
};

export default LogoWithTitle;
